/* nvd3 version 1.8.6-dev (https://github.com/novus/nvd3) 2018-02-24 */

.nvd3 {
    .nv-axis {
      pointer-events: none;
      opacity: 1;
  
      path {
        fill: none;
        stroke: #e5e5e5;
        stroke-opacity: .75;
        shape-rendering: crispEdges;
  
        &.domain {
          stroke-opacity: .75;
        }
      }
  
      &.nv-x path.domain {
        stroke-opacity: 0;
      }
  
      line {
        fill: none;
        stroke: #e5e5e5;
        shape-rendering: crispEdges;
      }
  
      .zero line, line.zero {
        stroke-opacity: .75;
      }
  
      .nv-axisMaxMin text {
        font-weight: bold;
      }
    }
  
    .x .nv-axis .nv-axisMaxMin text, .x2 .nv-axis .nv-axisMaxMin text, .x3 .nv-axis .nv-axisMaxMin text {
      text-anchor: middle;
    }
  
    .nv-axis.nv-disabled {
      opacity: 0;
    }
  
    .nv-bars {
      rect {
        fill-opacity: .75;
        transition: fill-opacity 250ms linear;
  
        &.hover {
          fill-opacity: 1;
        }
      }
  
      .hover rect {
        fill: lightblue;
      }
  
      text {
        fill: rgba(0, 0, 0, 0);
      }
  
      .hover text {
        fill: rgba(0, 0, 0, 1);
      }
    }
  
    .nv-multibar .nv-groups rect, .nv-multibarHorizontal .nv-groups rect, .nv-discretebar .nv-groups rect {
      stroke-opacity: 0;
      transition: fill-opacity 250ms linear;
    }
  
    .nv-multibar .nv-groups rect:hover, .nv-multibarHorizontal .nv-groups rect:hover, .nv-candlestickBar .nv-ticks rect:hover {
      fill-opacity: 1;
    }
  
    .nv-discretebar .nv-groups {
      rect:hover {
        fill-opacity: 1;
      }
  
      text {
        font-weight: bold;
        fill: rgba(0, 0, 0, 1);
        stroke: rgba(0, 0, 0, 0);
      }
    }
  
    .nv-multibarHorizontal .nv-groups text {
      font-weight: bold;
      fill: rgba(0, 0, 0, 1);
      stroke: rgba(0, 0, 0, 0);
    }
  
    .nv-boxplot {
      circle {
        fill-opacity: 0.5;
  
        &:hover {
          fill-opacity: 1;
        }
      }
  
      rect:hover {
        fill-opacity: 1;
      }
    }
  
    line.nv-boxplot-median {
      stroke: black;
    }
  }
  
  /* boxplot CSS */
  
  .nv-boxplot-tick:hover {
    stroke-width: 2.5px;
  }
  
  /* bullet */
  
  .nvd3 {
    &.nv-bullet {
      font: 10px sans-serif;
  
      .nv-measure {
        fill-opacity: .8;
  
        &:hover {
          fill-opacity: 1;
        }
      }
  
      .nv-marker {
        stroke: #000;
        stroke-width: 2px;
      }
  
      .nv-markerTriangle {
        stroke: #000;
        fill: #fff;
        stroke-width: 1.5px;
      }
  
      .nv-markerLine {
        stroke: #000;
        stroke-width: 1.5px;
      }
  
      .nv-tick line {
        stroke: #666;
        stroke-width: .5px;
      }
  
      .nv-range {
        &.nv-s0 {
          fill: #eee;
        }
  
        &.nv-s1 {
          fill: #ddd;
        }
  
        &.nv-s2 {
          fill: #ccc;
        }
      }
  
      .nv-title {
        font-size: 14px;
        font-weight: bold;
      }
  
      .nv-subtitle {
        fill: #999;
      }
  
      .nv-range {
        fill: #bababa;
        fill-opacity: .4;
  
        &:hover {
          fill-opacity: .7;
        }
      }
    }
  
    &.nv-candlestickBar .nv-ticks .nv-tick {
      stroke-width: 1px;
  
      &.hover {
        stroke-width: 2px;
      }
  
      &.positive rect {
        stroke: #2ca02c;
        fill: #2ca02c;
      }
  
      &.negative rect {
        stroke: #d62728;
        fill: #d62728;
      }
    }
  }
  
  .with-transitions .nv-candlestickBar .nv-ticks .nv-tick {
    transition: stroke-width 250ms linear, stroke-opacity 250ms linear;
  }
  
  .nvd3.nv-candlestickBar .nv-ticks line {
    stroke: #333;
  }
  
  .nv-force-node {
    stroke: #fff;
    stroke-width: 1.5px;
  }
  
  .nv-force-link {
    stroke: #999;
    stroke-opacity: .6;
  }
  
  .nv-force-node text {
    stroke-width: 0px;
  }
  
  .nvd3 {
    .nv-legend .nv-disabled rect {
      /*fill-opacity: 0;*/
    }
  
    .nv-check-box {
      .nv-box {
        fill-opacity: 0;
        stroke-width: 2;
      }
  
      .nv-check {
        fill-opacity: 0;
        stroke-width: 4;
      }
    }
  
    .nv-series.nv-disabled .nv-check-box .nv-check {
      fill-opacity: 0;
      stroke-opacity: 0;
    }
  
    .nv-controlsWrap .nv-legend .nv-check-box .nv-check {
      opacity: 0;
    }
  
    &.nv-linePlusBar .nv-bar rect {
      fill-opacity: .75;
  
      &:hover {
        fill-opacity: 1;
      }
    }
  
    .nv-groups path {
      &.nv-line {
        fill: none;
      }
  
      &.nv-area {
        stroke: none;
      }
    }
  
    &.nv-line .nvd3.nv-scatter .nv-groups .nv-point {
      fill-opacity: 0;
      stroke-opacity: 0;
    }
  
    &.nv-scatter.nv-single-point .nv-groups .nv-point {
      fill-opacity: .5 !important;
      stroke-opacity: .5 !important;
    }
  }
  
  /* line plus bar */
  
  .with-transitions .nvd3 .nv-groups .nv-point {
    transition: stroke-width 250ms linear, stroke-opacity 250ms linear;
  }
  
  .nvd3 {
    &.nv-scatter .nv-groups .nv-point.hover, .nv-groups .nv-point.hover {
      stroke-width: 7px;
      fill-opacity: .95 !important;
      stroke-opacity: .95 !important;
    }
  
    .nv-point-paths path {
      stroke: #aaa;
      stroke-opacity: 0;
      fill: #eee;
      fill-opacity: 0;
    }
  
    .nv-indexLine {
      cursor: ew-resize;
    }
  }
  
  /********************
   * SVG CSS
   */
  
  /********************
    Default CSS for an svg element nvd3 used
  */
  
  svg.nvd3-svg {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: block;
    width: 100%;
    height: 100%;
  }
  
  /********************
    Box shadow and border radius styling
  */
  
  .nvtooltip.with-3d-shadow, .with-3d-shadow .nvtooltip {
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  
  .nvd3 {
    text {
      font: normal 12px Arial, sans-serif;
    }
  
    .title {
      font: bold 14px Arial, sans-serif;
    }
  
    .nv-background {
      fill: white;
      fill-opacity: 0;
    }
  
    &.nv-noData {
      font-size: 18px;
      font-weight: bold;
    }
  }
  
  /**********
  *  Brush
  */
  
  .nv-brush {
    .extent {
      fill-opacity: .125;
      shape-rendering: crispEdges;
    }
  
    .resize path {
      fill: #eee;
      stroke: #666;
    }
  }
  
  /**********
  *  Legend
  */
  
  .nvd3 {
    .nv-legend {
      .nv-series {
        cursor: pointer;
      }
  
      .nv-disabled circle {
        fill-opacity: 0;
      }
    }
  
    .nv-brush .extent {
      fill-opacity: 0 !important;
    }
  
    .nv-brushBackground rect {
      stroke: #000;
      stroke-width: .4;
      fill: #fff;
      fill-opacity: .7;
    }
  
    &.nv-ohlcBar .nv-ticks .nv-tick {
      stroke-width: 1px;
  
      &.hover {
        stroke-width: 2px;
      }
  
      &.positive {
        stroke: #2ca02c;
      }
  
      &.negative {
        stroke: #d62728;
      }
    }
  
    .background path {
      fill: none;
      stroke: #EEE;
      stroke-opacity: .4;
      shape-rendering: crispEdges;
    }
  
    .foreground path {
      fill: none;
      stroke-opacity: .7;
    }
  
    .nv-parallelCoordinates-brush .extent {
      fill: #fff;
      fill-opacity: .6;
      stroke: gray;
      shape-rendering: crispEdges;
    }
  
    .nv-parallelCoordinates .hover {
      fill-opacity: 1;
      stroke-width: 3px;
    }
  
    .missingValuesline line {
      fill: none;
      stroke: black;
      stroke-width: 1;
      stroke-opacity: 1;
      stroke-dasharray: 5, 5;
    }
  
    &.nv-pie {
      path {
        stroke-opacity: 0;
        transition: fill-opacity 250ms linear, stroke-width 250ms linear, stroke-opacity 250ms linear;
      }
  
      .nv-pie-title {
        font-size: 24px;
        fill: rgba(19, 196, 249, 0.59);
      }
  
      .nv-slice text {
        stroke: #000;
        stroke-width: 0;
      }
  
      path {
        stroke: #fff;
        stroke-width: 1px;
        stroke-opacity: 1;
        fill-opacity: .7;
      }
  
      .hover path {
        fill-opacity: 1;
      }
  
      .nv-label {
        pointer-events: none;
  
        rect {
          fill-opacity: 0;
          stroke-opacity: 0;
        }
      }
    }
  
    .nv-groups .nv-point.hover {
      stroke-width: 20px;
      stroke-opacity: .5;
    }
  
    .nv-scatter .nv-point.hover {
      fill-opacity: 1;
    }
  }
  
  /* focus */
  
  /**********
  *  Print
  */
  
  @media print {
    .nvd3 text {
      stroke-width: 0;
      fill-opacity: 1;
    }
  }
  
  /* scatter */
  
  .nv-noninteractive, .nv-distx, .nv-disty {
    pointer-events: none;
  }
  
  /* sparkline */
  
  .nvd3 {
    &.nv-sparkline path {
      fill: none;
    }
  
    &.nv-sparklineplus {
      g.nv-hoverValue {
        pointer-events: none;
      }
  
      .nv-hoverValue line {
        stroke: #333;
        stroke-width: 1.5px;
      }
  
      pointer-events: all;
  
      g {
        pointer-events: all;
      }
    }
  
    .nv-hoverArea {
      fill-opacity: 0;
      stroke-opacity: 0;
    }
  
    &.nv-sparklineplus {
      .nv-xValue {
        stroke-width: 0;
        font-size: .9em;
        font-weight: normal;
      }
  
      .nv-yValue {
        stroke-width: 0;
        font-size: .9em;
        font-weight: normal;
        stroke: #f66;
      }
  
      .nv-maxValue {
        stroke: #2ca02c;
        fill: #2ca02c;
      }
  
      .nv-minValue {
        stroke: #d62728;
        fill: #d62728;
      }
  
      .nv-currentValue {
        font-weight: bold;
        font-size: 1.1em;
      }
    }
  
    &.nv-stackedarea {
      path.nv-area {
        fill-opacity: .7;
        stroke-opacity: 0;
        transition: fill-opacity 250ms linear, stroke-opacity 250ms linear;
  
        &.hover {
          fill-opacity: .9;
        }
      }
  
      .nv-groups .nv-point {
        stroke-opacity: 0;
        fill-opacity: 0;
      }
    }
  }
  
  /* stacked area */
  
  .nvtooltip {
    position: absolute;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(0, 0, 0, 1);
    padding: 1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    z-index: 10000;
    display: block;
    font-family: Arial, sans-serif;
    font-size: 13px;
    text-align: left;
    pointer-events: none;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
  
    &.with-transitions {
      transition: opacity 50ms linear;
      transition-delay: 200ms;
    }
  }
  
  /*Give tooltips that old fade in transition by
      putting a "with-transitions" class on the container div.
  */
  
  .with-transitions .nvtooltip {
    transition: opacity 50ms linear;
    transition-delay: 200ms;
  }
  
  .nvtooltip {
    &.x-nvtooltip, &.y-nvtooltip {
      padding: 8px;
    }
  
    h3 {
      margin: 0;
      padding: 4px 14px;
      line-height: 18px;
      font-weight: normal;
      background-color: rgba(247, 247, 247, 0.75);
      color: rgba(0, 0, 0, 1);
      text-align: center;
      border-bottom: 1px solid #ebebeb;
      border-radius: 5px 5px 0 0;
    }
  
    p {
      margin: 0;
      padding: 5px 14px;
      text-align: center;
    }
  
    span {
      display: inline-block;
      margin: 2px 0;
    }
  
    table {
      margin: 6px;
      border-spacing: 0;
  
      td {
        padding: 2px 9px 2px 0;
        vertical-align: middle;
  
        &.key {
          font-weight: normal;
  
          &.total {
            font-weight: bold;
          }
        }
  
        &.value {
          text-align: right;
          font-weight: bold;
        }
  
        &.percent {
          color: darkgray;
        }
      }
  
      tr.highlight td {
        padding: 1px 9px 1px 0;
        border-bottom-style: solid;
        border-bottom-width: 1px;
        border-top-style: solid;
        border-top-width: 1px;
      }
  
      td.legend-color-guide div {
        width: 8px;
        height: 8px;
        vertical-align: middle;
        width: 12px;
        height: 12px;
        border: 1px solid #999;
      }
    }
  
    .footer {
      padding: 3px;
      text-align: center;
    }
  }
  
  .nvtooltip-pending-removal {
    pointer-events: none;
    display: none;
  }
  
  /****
  Interactive Layer
  */
  
  .nvd3 {
    .nv-interactiveGuideLine {
      pointer-events: none;
    }
  
    line.nv-guideline {
      stroke: #ccc;
    }
  }