/**
Dimmer
*/

.dimmer {
	position: relative;
	.loader {
		display: none;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	&.active {
		.loader {
			display: block;
		}
		.dimmer-content {
			opacity: .04;
			pointer-events: none;
		}
	}
}


/*-------- Loaders ---------*/

.spinner {
	width: 40px;
	height: 40px;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.spinner-lg {
	width: 100px;
	height: 100px;
	margin: 100px auto;
	-webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
	animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-rotateplane {
	0% {
		-webkit-transform: perspective(120px);
	}
	50% {
		-webkit-transform: perspective(120px) rotateY(180deg);
	}
	100% {
		-webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
	}
}

@keyframes sk-rotateplane {
	0% {
		transform: perspective(120px) rotateX(0deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
	}
	50% {
		transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
		-webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
	}
	100% {
		transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
		-webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
	}
}

.spinner1 {
	width: 40px;
	height: 40px;
	position: relative;
	margin: 100px auto;
}

.spinner1-lg {
	width: 100px;
	height: 100px;
	position: relative;
	margin: 100px auto;
}

.double-bounce1 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
	width: 100%;
	height: 100%;
	border-radius: 50%;
	opacity: 0.6;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-bounce 2.0s infinite ease-in-out;
	animation: sk-bounce 2.0s infinite ease-in-out;
	-webkit-animation-delay: -1.0s;
	animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {
	0%,
	100% {
		-webkit-transform: scale(0);
	}
	50% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bounce {
	0%,
	100% {
		transform: scale(0);
		-webkit-transform: scale(0);
	}
	50% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.spinner2 {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
}

.cube1 {
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
}

.cube2 {
	width: 15px;
	height: 15px;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-animation: sk-cubemove 1.8s infinite ease-in-out;
	animation: sk-cubemove 1.8s infinite ease-in-out;
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}

@-webkit-keyframes sk-cubemove {
	25% {
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
	100% {
		-webkit-transform: rotate(-360deg);
	}
}

@keyframes sk-cubemove {
	25% {
		transform: translateX(42px) rotate(-90deg) scale(0.5);
		-webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
	}
	50% {
		transform: translateX(42px) translateY(42px) rotate(-179deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
	}
	50.1% {
		transform: translateX(42px) translateY(42px) rotate(-180deg);
		-webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
	}
	75% {
		transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
		-webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
	}
	100% {
		transform: rotate(-360deg);
		-webkit-transform: rotate(-360deg);
	}
}

.lds-heart {
	position: relative;
	width: 64px;
	height: 64px;
	transform: rotate(45deg);
	transform-origin: 32px 32px;
	margin: 100px auto;
	div {
		top: 23px;
		left: 19px;
		position: absolute;
		width: 26px;
		height: 26px;
		animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
		&:after {
			content: " ";
			position: absolute;
			display: block;
			width: 26px;
			height: 26px;
		}
		&:before {
			content: " ";
			position: absolute;
			display: block;
			width: 26px;
			height: 26px;
			left: -17px;
			border-radius: 50% 0 0 50%;
		}
		&:after {
			top: -17px;
			border-radius: 50% 50% 0 0;
		}
	}
}

@keyframes lds-heart {
	0% {
		transform: scale(0.95);
	}
	5% {
		transform: scale(1.1);
	}
	39% {
		transform: scale(0.85);
	}
	45% {
		transform: scale(1);
	}
	60% {
		transform: scale(0.95);
	}
	100% {
		transform: scale(0.9);
	}
}

.lds-ring {
	position: relative;
	width: 64px;
	height: 64px;
	margin: 100px auto;
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 51px;
		height: 51px;
		margin: 6px;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.lds-hourglass {
	position: relative;
	width: 64px;
	height: 64px;
	margin: 100px auto;
	&:after {
		content: " ";
		display: block;
		border-radius: 50%;
		width: 0;
		height: 0;
		margin: 6px;
		box-sizing: border-box;
		animation: lds-hourglass 1.2s infinite;
	}
}

@keyframes lds-hourglass {
	0% {
		transform: rotate(0);
		animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
	}
	50% {
		transform: rotate(900deg);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	100% {
		transform: rotate(1800deg);
	}
}