.header-search {
	min-width: 18rem !important;
}

.aside-header {
	padding: 1rem 1.5rem;
	border-bottom: 1px solid $border;
}

.header {
	background: $white;
	padding-top: .75rem;
	padding-bottom: .75rem;
	border-bottom: 1px solid $border;
}

body.fixed-header .header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 1030;
}

@media print {
	.header {
		display: none;
	}
}

.header {
	.dropdown-menu {
		margin-top: 0;
	}
	.mega-menu {
		width: 350px;
	}
	.nav-tabs {
		margin: 0;
	}
}

.header-right-icons {
	align-items: center;
	.dropdown-menu a {
		padding: 12px;
	}
}

.header-brand {
	color: inherit;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.6rem;
}

.app-header .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 200px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}

.app-header1 .header-brand {
	color: inherit;
	margin-right: 0;
	font-size: 1.25rem;
	white-space: nowrap;
	font-weight: 600;
	padding: 0;
	transition: .3s opacity;
	line-height: 2.9rem;
	min-width: 215px;
	text-align: center;
	.header-brand-img {
		margin-right: 0;
	}
}

.header-brand:hover {
	color: inherit;
	text-decoration: none;
}

.header-brand-img {
	height: 2.25rem;
       line-height: 2rem;
	vertical-align: middle;
	margin-right: .5rem;
	width: auto;
}

.header-avatar {
	width: 2rem;
	height: 2rem;
	display: inline-block;
	vertical-align: bottom;
	border-radius: 50%;
}

.header-btn {
	display: inline-block;
	width: 2rem;
	height: 2rem;
	line-height: 2rem;
	text-align: center;
	font-size: 1rem;
}

@media (max-width: 991px) {
	.header .form-inline {
		margin-right: -2px;
	}
}

@media (max-width: 991px) {
	.mobile-header {
		display: block;
		padding: .75rem 0;
		position: fixed;
		width: 100%;
		z-index: 999;
		background: $white;
		border-bottom: 1px solid $border;
		box-shadow: 0 8px 24px #f8f5fb;
		top:0;
		.navbar-toggler-icon {
			color: #76839a !important;
		}
	}
	.header-search-icon {
		display: none !important;
	}
	.side-header {
		display: none !important;
	}
	.header-brand-img {
		margin-right: 0;
		margin-left: 1.5rem;
	}
	.mobile-header {
		.header-brand-img {
			margin-top: 2px;
		}
		.header-brand {
			line-height: 0;
			margin: 0 auto;
		}
		.app-sidebar__toggle {
			padding: 2px 15px;
			color: #76839a;
			&:hover {
				color: #76839a;
			}
		}
		.nav-link.icon i {
			color: #495584 !important;
		}
		.header-settings {
			margin-top: 6px;
		}
		.profile-user {
			margin-top: 8px;
		}
	}
	.sidebar-mini.sidenav-toggled {
		.side-header {
			display: none;
		}
		.mobile-header .app-sidebar__toggle {
			display: block;
		}
	}
	.mobile-header {
		&.hor-mobile-header .header-brand-img {
			margin-left: 2.5rem;
			margin-top: 2px;
		}
		.profile-user {
			margin-top: 0px !important;
		}
		.nav-link.icon {
			padding: 12px !important;
			margin: 2px !important;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			height: 1.9rem !important;
		}
		.header-right-icons {
			.nav-link.icon:hover {
				background: none;
			}
			.profile-user:hover {
				box-shadow: none;
			}
		}
		.header-brand-img {
			&.desktop-logo {
				display: none;
			}
			&.mobile-light {
				display: block !important;
			}
		}
	}
	.header-right-icons .nav-link.icon{
		border: 1px solid $border;
	}
}

@media (max-width: 992px) and (min-width: 768px) {
	.hor-header .header-brand-img {
		&.desktop-logo {
			display: block;
			margin-left: 3rem;
			margin-top: 2px;
		}
		&.light-logo {
			display: none;
			margin-left: 3rem;
			margin-top: 2px;
		}
	}
	.animated-arrow {
		cursor: pointer;
		padding: 25px 35px 16px 0px !important;
		margin: 11px 0 0 15px;
	}
}

@media (max-width: 568px) {
	.mobile-header {
		.profile-1 .nav-link {
			padding-left: 0;
			padding-right: 0 !important;
		}
		.header-brand-img {
			margin-left: 0rem;
			margin-top: 2px;
		}
	}
}

@media (min-width: 768px) {
	.mobile-header {
		display: none;
	}
	.app-content .side-app {
		padding-top: 0px !important;
	}
}

.header {
	.form-inline {
		.form-control {
			border-color: rgba(225, 225, 225, 0.1);
			padding-left: 20px;
			width: 220px;
			padding-right: 40px;
			background: rgba(225, 225, 225, 0.3);
			color: $black !important;
		}
		.btn {
			border-radius: 0;
			padding: 5px 15px 6px 15px;
			border: 2px solid transparent;
			box-shadow: none;
			border-bottom-right-radius: 3px;
			border-top-right-radius: 3px;
			background: transparent;
			color: $white;
			margin-left: -56px;
			font-size: 15px;
		}
	}
	.header-brand-img.mobile-view-logo {
		display: none;
	}
	.dropdown-menu {
		padding: 0;
		border: 0;
	}
	.bg-image {
		background: linear-gradient(to right bottom, rgba(132, 65, 246, 0.7), rgba(212, 40, 234, 0.7));
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.bg-image-2 {
		background: linear-gradient(to right bottom, rgba(251, 196, 52, 0.7), rgba(246, 107, 78, 0.7));
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.bg-image-3 {
		background: linear-gradient(to right bottom, rgba(98, 251, 92, 0.6), rgba(33, 165, 6, 0.6));
		position: relative;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
	.bg-image a:hover {
		background: transparent !important;
	}
}

@media (max-width: 767.98px) and (min-width: 576px) {
	.header .form-inline .search-element {
		position: absolute;
		top: 8px;
		left: 10px;
		right: 10px;
		z-index: 892;
		display: none;
	}
	body.search-show .header .form-inline .search-element {
		display: block;
	}
	.header .navsearch i {
		font-size: 20px;
		color: $white;
	}
	.search-element .form-control {
		float: left;
		width: 100% !important;
		height: 40px;
		margin: 0 auto;
		background: $white !important;
		color: $black;
	}
	.search-show .search-element {
		display: block;
		left: 0 !important;
		top: 0 !important;
		right: 0;
		width: 100%;
		padding: 10px 13px;
	}
	.header {
		.form-inline .form-control::-webkit-input-placeholder {
			color: #76839a;
		}
		.navsearch i {
			font-size: 20px;
			color: $white;
			padding-top: 5px;
		}
		.form-inline .btn {
			color: #46494a !important;
			padding: 8px 15px 6px 15px;
		}
	}
}

@media (max-width: 568px) {
	.header .input-icon .form-control {
		display: block !important;
	}
	.page-main .header {
		top: 0px !important;
	}
}

.header-1 {
	.navbar .nav-link.nav-link-lg i {
		font-size: 1.1rem;
		width: 2.2rem;
		height: 2.2rem;
		border-radius: 50%;
		color: $default-color;
		line-height: 32px;
		margin: 0 auto;
		text-align: center;
	}
	.nav-link .badge {
		position: absolute;
		top: 3px;
		right: 6px;
		width: 1rem;
		height: 1rem;
		font-size: 11px !important;
		text-align: center !important;
		padding: 0.25em 0.4em;
		line-height: 1;
	}
	.badge {
		border-radius: 50%;
	}
	.form-inline .btn {
		border-radius: 0;
		padding: 5px 15px 6px 15px;
		border: 2px solid transparent;
		box-shadow: none;
		border-bottom-right-radius: 3px;
		border-top-right-radius: 3px;
		background: transparent;
		color: $default-color;
		margin-left: -50px;
		font-size: 15px;
		position: inherit;
		margin-top: 3px;
	}
	.form-control.header-search {
		color: $default-color;
	}
	.form-inline .form-control::-webkit-input-placeholder {
		color: #76839a;
	}
	.animated-arrow span {
		display: none;
	}
}

.animated-arrow span {
	&:before,
	&:after {
		display: none;
	}
}

@media only screen and (max-width: 991px) {
	.header-1 .animated-arrow span {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: $default-color;
		position: absolute;
		display: block;
		content: '';
	}
	.animated-arrow span {
		&:before,
		&:after {
			cursor: pointer;
			height: 2px;
			width: 17px;
			background: $default-color;
			position: absolute;
			display: block;
			content: '';
		}
	}
	.header-1 .animated-arrow span {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: $default-color;
		position: absolute;
		display: block;
		content: '';
	}
	.animated-arrow span {
		&:before,
		&:after {
			cursor: pointer;
			height: 2px;
			width: 17px;
			background: $default-color;
			position: absolute;
			display: block;
			content: '';
		}
	}
	.header-1 {
		.hor-toggle.animated-arrow {
			padding: 83px 35px 16px 20px !important;
			margin: 11px 0 0 0px !important;
		}
		.animated-arrow {
			cursor: pointer;
			margin: 0px 0 0 -11px;
			position: initial;
		}
	}
	.header-2 .animated-arrow {
		cursor: pointer;
		margin: 0px 0 0 -11px;
		position: initial;
	}
	.header-1 .form-inline .form-control,
	.header-2 .form-inline .form-control {
		width: 100PX !important;
	}
	.header-1 .header-search,
	.header-2 .header-search {
		min-width: auto !important;
	}
	.header-1 {
		.navbar .nav-link.nav-link-lg i {
			font-size: 1.1rem;
			width: 2.2rem;
			height: 2.2rem;
			border-radius: 50%;
			color: $default-color;
			line-height: 39px;
			margin: 0 auto;
			text-align: center;
			padding: 0px 42px 0px 10px;
		}
		.dropdown-menu {
			box-shadow: 0 6px 12px 0 $black-05;
		}
		.nav-link .badge {
			position: absolute;
			top: 4px;
			width: 1rem;
			height: 1rem;
			font-size: 11px;
			text-align: center !important;
			padding: 0.25em 0.4em;
			line-height: 1;
		}
	}
}

.header-1 {
	.nav-link.icon i {
		color: #5b6e88 !important;
	}
	.dropdown-menu {
		box-shadow: 0 6px 12px 0 $black-05;
	}
}

.header-2 {
	.dropdown-menu {
		box-shadow: 0 6px 12px 0 $black-05;
	}
	.nav-link {
		.badge {
			position: absolute;
			top: 9px;
			right: 8px;
			width: 1rem;
			height: 1rem;
			font-size: 11px;
			text-align: center !important;
			padding: 0.25em 0.4em;
			line-height: 1.3;
			border-radius: 50%;
		}
		i {
			display: block;
			text-align: center;
			font-size: 20px;
			color: $default-color;
			margin-top: 5px;
		}
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.9rem;
		padding-left: 0.9rem;
	}
	.form-inline {
		.btn {
			border-radius: 0;
			padding: 5px 15px 6px 15px;
			border: 2px solid transparent;
			box-shadow: none;
			border-bottom-right-radius: 3px;
			border-top-right-radius: 3px;
			background: transparent;
			color: $default-color;
			margin-left: -45px;
			font-size: 15px;
			position: absolute;
			margin-top: 6px;
		}
		.form-control::-webkit-input-placeholder {
			color: #76839a;
		}
	}
	.form-control.header-search {
		background: rgba(225, 225, 225, 0.4);
		border: 1px solid rgba(225, 225, 225, 0.1);
		color: $white;
		padding: 0.375rem 0.75rem;
		margin-top: 4px;
	}
}

@media only screen and (max-width: 991px) {
	.header-1 .animated-arrow span {
		cursor: pointer;
		height: 2px;
		width: 17px;
		background: $default-color;
		color: $default-color;
		position: absolute;
		display: block;
		content: '';
		&:before,
		&:after {
			cursor: pointer;
			height: 2px;
			width: 17px;
			background: $default-color;
			color: $default-color;
			position: absolute;
			display: block;
			content: '';
		}
	}
}


/*--header-2--*/

@media only screen and (max-width: 991px) {
	.header-2 {
		.animated-arrow {
			cursor: pointer;
			padding: 8px 33px 3px 0px;
			margin: 11px 0 0 -11px;
			span {
				cursor: pointer;
				height: 2px;
				width: 17px;
				background: $default-color;
				color: $default-color;
				position: absolute;
				display: block;
				content: '';
				&:before,
				&:after {
					cursor: pointer;
					height: 2px;
					width: 17px;
					background: $default-color;
					color: $default-color;
					position: absolute;
					display: block;
					content: '';
				}
			}
		}
		.nav-link .badge {
			position: absolute;
			// top: -1px;
			right: 6px;
			width: 1rem;
			height: 1rem;
			font-size: 11px;
			text-align: center !important;
			padding: 0.25em 0.4em;
			line-height: 1;
			border-radius: 50%;
		}
		.navbar-nav .dropdown-menu {
			position: absolute;
			float: right;
		}
		.dropdown-menu.dropdown-menu-right {
			position: absolute;
			transform: translate3d(-98px, 19px, -16px);
			top: 24px;
			left: 0px;
			will-change: transform;
		}
	}
}

@media only screen and (max-width: 460px) {
	.header-2 .navbar-nav .envelope {
		display: none !important;
	}
}


/*--header-3--*/

.header-3 {
	.nav-link {
		.badge {
			position: absolute;
			top: -10px;
			right: 10px;
			width: 1rem;
			height: 1rem;
			font-size: 11px;
			text-align: center !important;
			padding: 0.25em 0.4em;
			line-height: 1;
			border-radius: 50%;
		}
		i {
			display: block;
			text-align: center;
			font-size: 20px;
			position: relative;
			color: $default-color;
		}
	}
	.navbar-expand-lg .navbar-nav .nav-link {
		padding-right: 0.9rem;
		padding-left: 0.9rem;
	}
	.form-inline {
		.btn {
			border-radius: 0;
			padding: 5px 15px 6px 15px;
			border: 2px solid transparent;
			box-shadow: none;
			border-bottom-right-radius: 3px;
			border-top-right-radius: 3px;
			background: transparent;
			color: $default-color;
			margin-left: -56px;
			font-size: 15px;
		}
		.form-control::-webkit-input-placeholder {
			color: #76839a;
		}
	}
	.form-control.header-search {
		background: rgba(225, 225, 225, 0.4);
		border: 1px solid rgba(225, 225, 225, 0.1);
		color: $white;
		padding: 0.375rem 0.75rem;
		margin-top: 4px;
	}
}

@media only screen and (max-width: 991px) {
	.header-3 {
		.animated-arrow {
			cursor: pointer;
			padding: 32px 33px 3px 0px;
			margin: 11px 0 0 -11px;
			span {
				cursor: pointer;
				height: 2px;
				width: 17px;
				background: $default-color;
				color: $default-color;
				position: absolute;
				display: block;
				content: '';
				&:before,
				&:after {
					cursor: pointer;
					height: 2px;
					width: 17px;
					background: $default-color;
					color: $default-color;
					position: absolute;
					display: block;
					content: '';
				}
			}
		}
		.nav-link .badge {
			position: absolute;
			top: -14px;
			right: 6px;
			width: 1rem;
			height: 1rem;
			font-size: 11px;
			text-align: center !important;
			padding: 0.25em 0.4em;
			line-height: 1;
			border-radius: 50%;
		}
		.navbar-nav .dropdown-menu {
			position: absolute;
			float: right;
		}
		.dropdown-menu.dropdown-menu-right {
			position: absolute;
			transform: translate3d(-98px, 19px, -16px);
			top: 24px;
			left: 0px;
			will-change: transform;
		}
	}
}

@media only screen and (max-width: 460px) {
	.header-3 .navbar-nav .envelope {
		display: none !important;
	}
}

.header-style .header-brand {
	margin-left: 0 !important;
}

.default-header .form-inline .form-control,
.header2 .form-inline .form-control,
.header3 .form-inline .form-control {
	background: #f1f1f9 !important;
}

@media only screen and (max-width: 991px) {
	.page-header {
		padding: 4px;
	}
	.app-header {
		box-shadow: none !important;
		position: fixed !important;
	}
	.page {
		position: inherit;
		background: none;
	}
	body {
		background-color: #f1f1f9;
	}
	.page-main {
		z-index: inherit;
	}
}

@media only screen and (min-width: 768px) {
	.header .form-inline .form-control {
		width: 200px;
	}
}

.hor-header {
	.header-brand-img {
		margin-right: 0;
	}
	.header-brand1 {
		color: inherit;
		font-size: 1.25rem;
		white-space: nowrap;
		font-weight: 600;
		padding: 0;
		transition: .3s opacity;
		line-height: 2.8rem;
		margin-top: 2px;
	}
}

.header-right-icons {
	.nav-link.icon:hover {
		background: none;
		border-radius: 50px;
	}
	.profile-user:hover {
		box-shadow: none;
	}
}


/*Logo-center header */

@media (min-width: 991px) {
	.hor-topheader {
		.header-brand1 .main-logo {
			display: none;
		}
		.header-brand2 {
			margin-left: auto;
		}
	}
}

@media (max-width: 992px) and (min-width: 768px) {
	.hor-topheader .header-brand-img.main-logo {
		display: block;
		margin-left: 3rem;
		margin-top: 2px;
	}
	.color-hor-header .hor-topheader .header-brand1 .header-brand-img.main-logo,
	.gradient-hor-header .hor-topheader .header-brand1 .header-brand-img.main-logo {
		display: none;
	}
}

.hor-topheader {
	.header-search.dropdown-menu {
		box-shadow: 0 10px 15px rgba(229, 228, 230, 0.5);
		margin-top: 13px !important;
		border: 1px solid $border !important;
	}
	.dropdown-menu.show {
		display: block;
		margin: 0;
		border: 1px solid #eaf2f6;
		transform: translate3d(8px, 45px, 0px) !important;
	}
	.dropdown-menu-arrow.dropdown-menu-right {
		&:before,
		&:after {
			right: auto;
			left: 12px;
		}
	}
}

@media (max-width: 480px) {
	.app-header .header-brand,
	.app-header1 .header-brand {
		min-width: auto;
	}
	.header-right-icons .dropdown .header-toggler {
		margin-top: 9px;
	}
}

@media (max-width: 992px) {
	.header {
		// border-bottom: 1px solid $white-2;
		padding-top: .75rem;
		padding-bottom: .75rem;
	}
}
.header {
	.notifications{
		.dropdown-menu{
			min-width: 16rem;
		}
	}
	.dropdown.notifications, .dropdown.message{
		.dropdown-item{
			padding: 1rem !important;
		}
	}
	.dropdown-menu{
		box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .3);
		border: 1px solid $border;
		top:3.5rem !important;
		.badge{
			padding: 9px 6px !important;
		}
		.dropdown-item{
			border-bottom: 1px solid $border;
			&:last-child{
				border-bottom: 0;
			}
		}
	}
	.dropdown-item{
		color: $default-color;
	}
	.notifications-menu, .message-menu {
		h5{
			font-size: 13.5px;
			color: $default-color;
			font-weight: 500;
		}
		span{
			font-size: 12px;
			color:#76839a;
		}
	}
	.dropdown-menu{
		box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .3);
		border: 1px solid $border;
		.badge{
			padding: 3px 6px;
		}
		.dropdown-item{
			border-bottom: 1px solid $border;
			&:last-child{
				border-bottom: 0;
			}
		}
	}
	.message-menu .wd-90p{
		width: 90%;
	}
	.profile-1{
		.dropdown-item{
			padding:.75rem 1.75rem !important;
			.dropdown-icon{
				font-size: 16px;
				color: $default-color;
			}
		}
	}
}
.responsive-navbar{
	.notifications-menu, .message-menu {
		h5{
			font-size: 13.5px;
			color: $default-color;
			font-weight: 500;
		}
		span{
			font-size: 12px;
			color:#76839a;
		}
	}
	.dropdown-menu{
		box-shadow: 0 10px 40px 0 rgba(104, 113, 123, .3);
		border: 1px solid $border;
		.dropdown-item{
			border-bottom: 1px solid $border;
			color:#0d0c22;
			&:last-child{
				border-bottom: 0;
			}
		}
	}
	.message-menu .wd-90p{
		width: 90%;
	}
	.profile-1{
		.dropdown-item{
			padding:.75rem 1.75rem !important;
			.dropdown-icon{
				font-size: 16px;
				color: $default-color;
			}
		}
	}
}	
.header-1, .header-2,  .header-1.header-style {
	.header-brand-img.logo{
		display: none;
	}
}
.header{
	&.header-1.header-style {
		.dropdown-menu{
			top:inherit !important;
		}
	}
}
.dropdown .avatar.profile-user{
	width: 2.4rem;
    height: 2.4rem;
    line-height: 2.2rem;
}
.responsive-logo .mobile-logo.dark-logo-1 {
    display: none;
}
.navbar-collapse{
	.d-flex.order-lg-2{
		align-items: center;
	}
}
.header-1{
	.header-right-icons .nav-link.icon {
		border: 0;
	}
}
.app-header, .hor-header{
	.main-header-center{
		.btn{
			top: -2px;
		}
	}
}

@media(min-width:992px){
	.header{
		.dropdown.message{
			.dropdown-menu{
				min-width: 19rem;
			}
		}
	}
}

.app-header, .hor-header{
    .responsive-navbar{
        .notifications-menu, .message-menu{
            .dropdown-item h5, .dropdown-item span{
                white-space: normal !important;
                word-wrap: break-word !important;
                word-break: break-word !important;
            }
        }
        .notifications-menu .wd-80p{
            width: 80%;
        }
    }
}