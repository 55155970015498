﻿/*
    Common 
*/

.wizard, .tabcontrol {
    display: block;
    width: 100%;
    overflow: hidden;
  }
  
  .wizard a, .tabcontrol a {
    outline: 0;
  }
  
  .wizard ul, .tabcontrol ul {
    list-style: none !important;
    padding: 0;
    margin: 0;
  }
  
  .wizard ul > li, .tabcontrol ul > li {
    display: block;
    padding: 0;
  }
  
  /*
      Wizard
  */
  
  .wizard {
    > .steps {
      position: relative;
      display: block;
      width: 100%;
    }
  
    &.vertical {
  
      > .steps > ul > li {
        float: none;
      }
    }
  
    > {
      .steps {
        > ul > li {
          width: 25%;
          float: left;
        }
      }
  
      .actions > ul > li {
        float: left;
      }
    }
  
    > {
      .content > .body {
        float: left;
        position: absolute;
        width: 95%;
        height: 95%;
        padding: 2.5%;
  
        ul {
          list-style: disc !important;
  
          > li {
            display: list-item;
          }
        }
  
        > iframe {
          border: 0 none;
          width: 100%;
          height: 100%;
        }
  
        input {
          display: block;
  
          &[type="checkbox"] {
            display: inline-block;
          }
  
          &.error {
            background: rgb(251, 227, 228);
            border: 1px solid #fbc2c4;
            color: #8a1f11;
          }
        }
  
        label {
          display: inline-block;
          margin-bottom: 0.5em;
  
          &.error {
            color: #8a1f11;
            display: inline-block;
            margin-left: 1.5em;
          }
        }
      }
  
      .actions {
        position: relative;
        display: block;
        text-align: right;
        width: 100%;
      }
    }
  
    > .actions > ul {
      display: inline-block;
      text-align: right;
    }
  
    > {
      .actions {
        a {
          background: #2184be;
          color: #fff;
          display: block;
          padding: 0.5em 1em;
          text-decoration: none;
          -webkit-border-radius: 5px;
          -moz-border-radius: 5px;
          border-radius: 5px;
  
          &:hover, &:active {
            background: #2184be;
            color: #fff;
            display: block;
            padding: 0.5em 1em;
            text-decoration: none;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
          }
        }
      }
  
      .loading .spinner {}
    }
  }
  
  /*
      Tabcontrol
  */
  
  .tabcontrol > {
    .steps {
      position: relative;
      display: block;
      width: 100%;
  
      > ul {
        position: relative;
        margin: 6px 0 0 0;
        top: 1px;
        z-index: 1;
  
        > li {
          float: left;
          margin: 5px 2px 0 0;
          padding: 1px;
          -webkit-border-top-left-radius: 5px;
          -webkit-border-top-right-radius: 5px;
          -moz-border-radius-topleft: 5px;
          -moz-border-radius-topright: 5px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
  
          &:hover {
            background: #edecec;
            border: 1px solid #bbb;
            padding: 0;
          }
  
          &.current {
            background: #fff;
            border: 1px solid #bbb;
            border-bottom: 0 none;
            padding: 0 0 1px 0;
            margin-top: 0;
  
            > a {
              padding: 15px 30px 10px 30px;
            }
          }
  
          > a {
            color: #5f5f5f;
            display: inline-block;
            border: 0 none;
            margin: 0;
            padding: 10px 30px;
            text-decoration: none;
  
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  
    .content {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 35em;
      overflow: hidden;
      border-top: 1px solid #bbb;
      padding-top: 20px;
  
      > .body {
        float: left;
        position: absolute;
        width: 95%;
        height: 95%;
        padding: 2.5%;
  
        ul {
          list-style: disc !important;
  
          > li {
            display: list-item;
          }
        }
      }
    }
  }
.wizard.vertical>.steps .current a .title {
    display: inline-block;
}

@media (max-width: 360px){
  .wizard>.steps>ul li .title {
    display: none !important;
  }
}