.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	> {
		.form-control, .form-select, .form-file {
			position: relative;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 1%;
			margin-bottom: 0;
		}
		.form-control:focus, .form-select:focus, .form-file:focus {
			z-index: 2;
		}
		.form-control+ {
			.form-control, .form-select, .form-file {
				margin-left: -1px;
			}
		}
		.form-select+ {
			.form-control, .form-select, .form-file {
				margin-left: -1px;
			}
		}
		.form-file+ {
			.form-control, .form-select, .form-file {
				margin-left: -1px;
			}
		}
		.form-control:not(:last-child), .form-select:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.form-control:not(:first-child), .form-select:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.form-file {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			&:not(:last-child) .form-file-label {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				&::after {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			&:not(:first-child) .form-file-label {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				&::after {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}
.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 0.9;
	color: #94a2af;
	text-align: center;
	white-space: nowrap;
	background-color: #f1f1f98a;
	border: 1px solid #d8dde4;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-right: 0;
	input {
		&[type="radio"], &[type="checkbox"] {
			margin-top: 0;
		}
	}
}
.input-group-btn {
	font-size: 0.9375rem;
	border: 0;
}
.input-group-btn>.btn {
	height: 100%;
	border-color: rgb(36, 196, 180);
}
.input-group-text {
	border-right: 0;
}
.input-group-text {
	border-left: 0;
}
.input-icon .form-group.is-empty {
	.form-control {
		margin-bottom: 0;
	}
	margin-bottom: 0;
}
.input-group-addon {
	padding: 10px 0px 6px 12px;
	width: 38px;
	height: 38px;
	font-size: 20px;
	font-weight: 400;
	line-height: 1 !important;
	color: #555;
	text-align: center;
	border-right: 0;
	border-radius: 4px 0 0 4px;
}
.input-indec .input-group-btn>.btn {
	height: 100%;
	border-color: $border;
}
.input-group {
	position: relative;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-ms-flex-align: stretch;
	align-items: stretch;
	width: 100%;
	> {
		.form-control, .form-select, .form-file {
			position: relative;
			-ms-flex: 1 1 auto;
			flex: 1 1 auto;
			width: 1%;
			margin-bottom: 0;
		}
		.form-control:focus, .form-select:focus, .form-file:focus {
			z-index: 2;
		}
		.form-control+ {
			.form-control, .form-select, .form-file {
				margin-left: -1px;
			}
		}
		.form-select+ {
			.form-control, .form-select, .form-file {
				margin-left: -1px;
			}
		}
		.form-file+ {
			.form-control, .form-select, .form-file {
				margin-left: -1px;
			}
		}
		.form-control:not(:last-child), .form-select:not(:last-child) {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.form-control:not(:first-child), .form-select:not(:first-child) {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		.form-file {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			&:not(:last-child) .form-file-label {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				&::after {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}
			}
			&:not(:first-child) .form-file-label {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				&::after {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}
		}
	}
}
.input-group-text {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	padding: 0.375rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	font-weight: 400;
	line-height: 0.9;
	color: #86909a;
	text-align: center;
	white-space: nowrap;
	border: 1px solid $border;
	input {
		&[type="radio"], &[type="checkbox"] {
			margin-top: 0;
		}
	}
}
.input-icon .form-group.is-empty {
	.form-control {
		margin-bottom: 0;
	}
	margin-bottom: 0;
}
.input-group-addon {
	padding: 10px 0px 6px 12px;
	width: 38px;
	height: 38px;
	font-size: 20px;
	font-weight: 400;
	line-height: 1 !important;
	color: #555;
	text-align: center;
	border-right: 0;
	border-radius: 4px 0 0 4px;
}
.input-indec .input-group-btn>.btn {
	height: 100%;
	border-color: $border;
}