.tab-content> {
	.tab-pane {
		display: none;
	}
	.active {
		display: block;
	}
}

.tabs-menu ul li {
	a {
		padding: 10px 12px 11px 13px;
		display: block;
	}
	.active {
		color: $primary-1;
	}
}

.tabs-menu1 ul li a {
	padding: 10px 20px 11px 20px;
	display: block;
}

.tabs-menu-body {
	border: 1px solid $border;
	p:last-child {
		margin-bottom: 0;
	}
}

.tab-menu-heading {
	padding: 20px;
	border: 1px solid $border;
	border-bottom: 0;
}

.tab_wrapper .content_wrapper .tab_content.active p:last-child {
	margin-bottom: 0;
}

.tabs-menu2 ul li {
	a {
		color: #636262;
		padding: 10px 20px 11px 20px;
	}
	.active {
		font-weight: 600;
	}
	.fade {
		color: #eeee;
	}
}
.tab_wrapper.second_tab.right_side{
	.tab_list li{
		border-radius: 0px;
	}
	.tab_list li:first-child{
		border-radius: 5px 5px 0 0;
	}
	.tab_list li:last-child{
		border-radius: 0 0px 5px 5px;
		border-bottom: 1px solid $border;
	}
}