/*------ Breadcrumb ------*/

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0;
	list-style: none;
	border-radius: 3px;
	position: relative;
	right: 0;
	padding: 0;
	color: $white;
	background: none;
}
.breadcrumb-item {
	a {
		color: #76839a;
	}
	+.breadcrumb-item {
		&::before {
			display: inline-block;
			color: rgba(110, 126, 150, 0.3);
			content: "/";
		}
		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
}
.breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-bottom: 0;
	list-style: none;
	background-color: transparent;
	border-radius: .25rem;
}
.breadcrumb2 {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: .25rem;
}
.breadcrumb-item1 {
	a:hover {
		color: $primary-1;
		text-decoration: none;
	}
	+.breadcrumb-item1 {
		&::before {
			display: inline-block;
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			content: "/";
			color: #cec5c5;
			opacity: 0.5;
		}
		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
	&.active {
		color: #495584;
	}
}
.breadcrumb-item2 {
	a:hover {
		color: $primary-1;
		text-decoration: none;
	}
	+.breadcrumb-item2 {
		&::before {
			display: inline-block;
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			content: "\e92f";
			color: #cec5c5;
			opacity: 0.5;
			font-family:'feather';
		}
		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
	&.active {
		color: #495584;
	}
}
.breadcrumb-3 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
	}
	li {
		display: contents;
		text-align: center;
	}
}
.breadcrumb-4 li, .breadcrumb-5 li {
	display: contents;
	text-align: center;
}
.breadcrumb-4 ol, .breadcrumb-5 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: center;
}
.breadcrumb-1 li, .breadcrumb-2 li, .breadcrumb-6 li {
	text-align: right;
	display: contents;
}
.breadcrumb-1 ol, .breadcrumb-2 ol, .breadcrumb-6 ol {
	position: relative;
	display: block;
	width: 100%;
	text-align: right;
}
.breadcrumb-item {
    display: -ms-flexbox;
    display: block !important;
}