.countdown-timer-wrapper {
	border-radius: 5px;
	margin-bottom: 20px;
	max-width: 500px;
	margin: 30px auto;
  
	h5 {
	  font-size: 14px;
	  letter-spacing: 0.5px;
	  text-align: center;
	  padding-top: 10px;
	  text-shadow: none;
	}
  
	.timer {
	  padding: 10px;
	  text-align: center;
	  padding-top: 15px;
  
	  .timer-wrapper {
		display: inline-block;
		width: 110px;
		height: 50px;
  
		.time {
		  font-size: 35px;
		  font-weight: bold;
		  color: #fff;
		  width: 80px;
		  height: 80px;
		  border-radius: 0;
		  background: linear-gradient(to bottom right, rgb(0, 0, 0, 0.1), rgb(0, 0, 0, 0.1));
		  line-height: 2.3;
		  text-align: center;
		  margin: 0 auto;
		  margin-bottom: 5px;
		  position: relative;
		  overflow: hidden;
  
		  &:before {
			content: '';
			background-repeat: no-repeat;
			position: absolute;
			width: 100%;
			height: 100%;
			right: -15px;
		  }
		}
  
		.text {
		  font-size: 20px;
		  color: rgba(255, 255, 255, 0.6);
		  padding-top: 4px;
		}
	  }
	}
  }